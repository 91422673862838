.App {
    display: flex;
    width: 100%;
    align-items: stretch;
}

@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";

body {
    font-family: "Poppins", sans-serif;
    background: #fafafa2d;
}

a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.sidebar {
    min-width: 250px;
    max-width: 250px;
    /* background: #f7f6f6; */
    /* background: #F8F9FA; */
    background: linear-gradient(to bottom, #645F59, black);

    color: rgb(255, 255, 255);
    margin-left: -250px;
    transition: all 0.5s;
    /* position: fixed; */
    /* position: ; */
}

.sidebar.is-open {
    margin-left: 0;
    transition: 0.5s;
}

.sidebar-header {
    background: #fafafac9;
}

.sidebar-header h3 {
    color: #fff;
    padding: 1em;
}

.sidebar ul p {
    color: #fff;
    padding: 10px;
}

.menu-open {
    /* background: #ffffffac; */
    background: #2d9abbf8;
}

.nav-item:hover {
    /* color: #ebe7e7;
    background: rgba(238, 228, 228, 0.972); */
    color: #000000;
    background: #2d9abbf8;
}

.items-menu {
    color: #fff;
    background: #f5f5f5;
}

li a.dropdown-toggle-x::after {
    display: inline-flex;
    position: relative;
    left: 60%;
    top: 10%;
}

.sidebar-header>span {
    position: relative;
    float: right;
    margin: 0.5em;
    font-size: 2rem;
    cursor: pointer;
    display: none;
}

.side-menu {
    /* height: calc(100vh - 130px); */
    /* position: fixed; */
    overflow-y: auto;

}

.side-menu::-webkit-scrollbar {
    width: 10px;
}

.side-menu::-webkit-scrollbar-thumb {
    background: #5466b3;
    border-radius: 3px;
}

.side-menu::-webkit-scrollbar-thumb:hover {
    background: #3a4c99;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
.content {
    padding: 20px;
    margin-left: 0;
    height: 100vh;
    overflow: auto;
}

.tales {
    width: 100%;
}

.carousel-inner {
    width: 100%;

    max-height: 400x !important;
}

.item {
    text-align: center;
}

.carousel-inner img {
    display: inline-block !important;
}


@media only screen and (max-width: 500px) {
    /* body {
        overflow: hidden;
    } */

    .content.is-open {
        margin-left: 100%;
    }

    .sidebar.is-open {
        min-width: 100%;
        max-width: 100%;
        margin-left: 0;
        transition: all 0.5s, height 0s;
    }

    .sidebar.is-open>.sidebar-header span {
        display: unset;
    }

    li a.dropdown-toggle-x::after {
        display: inline-block;
        position: relative;
        left: 68%;
    }
}